import React from 'react'
import { View, TouchableOpacity, Text, ScrollView } from 'react-native'
import { Entypo } from '@expo/vector-icons';

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.dropViewRef = React.createRef()
        this.state = {
            active: false,
            value: '',
            Index: 0
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.sureValue = this.sureValue.bind(this)
    }
    handleCancel(event) {
        if (this.state.active && !this.dropViewRef.current.contains(event.target)) {
            this.setState({ active: false })
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.handleCancel)
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.handleCancel)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data.length > 0) {
            if (this.state.Index === 0) {
                this.props.onChange && this.props.onChange(this.props.data[0])
            }
        }
    }

    sureValue(text) {
        this.setState({ value: text, active: false })
        this.props.onChange && this.props.onChange(text)
    }




    render() {
        return (
            <View style={{ width: '100%', borderBottomWidth: 1, borderColor: '#dee2e6', }} ref={this.dropViewRef} >
                <TouchableOpacity style={{ width: '100%', padding: 12, paddingLeft: 0 }} onPress={() => this.setState({ active: !this.state.active, Index: 1 })} >
                    <Text style={{ fontWeight: 'bold', fontSize: 16, color: 'white', textTransform: 'capitalize' }} >{this.state.value.length === 0 ? this.props.data[0] : this.state.value}</Text>
                    <Entypo name="chevron-thin-down" size={15} color="white" style={{ position: 'absolute', right: 5, top: 15 }} />
                </TouchableOpacity>
                <View style={{ height: 250, backgroundColor: '#fff', position: 'absolute', width: '100%', zIndex: 100000, top: 47, display: this.state.active ? 'flex' : 'none' }} >
                    <ScrollView>
                        {
                            this.props.data && this.props.data.map((item, index) => {
                                return (
                                    <TouchableOpacity key={'time' + index.toString()} style={{ width: '100%', paddingHorizontal: 20 }} onPress={() => this.sureValue(item)} >
                                        <Text style={{ borderBottomWidth: 1, borderColor: '#dee2e6', paddingVertical: 17, fontSize: 16, color: '#495057', textTransform: 'capitalize', fontWeight: '500' }} key={index}>{item}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ScrollView>
                </View>
            </View>
        )
    }

}

export default Index