import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Dimensions, ScrollView, TextInput, Image } from 'react-native'
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as cartAction from '../../store/actions/cart'
import DropDownTime from './dropDownTime'
import moment from 'moment';
import Constant from "../../until/constant";

const Index = (props) => {
    const dispatch = useDispatch();

    const [storeAvailableDate, setstoreAvailableDate] = useState([]);
    const [storeAvailableHours, setstoreAvailableHours] = useState([]);
    const [temList, setTemList] = useState([])
    const [scheduleTime, setScheduleTime] = useState('')
    // const [showTimeVisible, setShowTimeVisible] = useState(false);
    // const [calculatedOrder, setCalculatedOrder] = useState();
    const order = useSelector(state => state.cart);

    // const storeinfo = localStorage.storeinfo && JSON.parse(localStorage.storeinfo); // 饭店信息

    useEffect(() => {

        fetch(`${Constant.endPointUrl}/secure/api/tapthru/restaurantInfoHour/getAvailableHours?restaurantInfoId=3`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then(response => response.json())
            .then(responseText => {
                var results = []
                responseText.forEach(el => {
                    results.push(`${el.today ? 'Today' : el.dayOfWeek.toLowerCase()} • ${moment(el.currentDate).format('LL')}`)
                })

                setTemList(responseText)
                setstoreAvailableDate(results)
                setstoreAvailableHours(order.orderType === 'DELIVERY' ? responseText[0].deliverySelectableTimes : responseText[0].pickupSelectableTimes)
            });



    }, [])
    // useEffect(() => {
    //     localStorage.localCart && calculateOrderViaServer(true);
    // }, [])

    // useEffect(() => {
    //     setShowTimeVisible(props.show)
    // }, [props])


    // const calculateOrderViaServer = (isAddressChanged = false) => {
    //     const tempLocalOrder = localStorage.localCart && JSON.parse(localStorage.localCart);
    //     if (tempLocalOrder.orderType == 'DELIVERY') {
    //         tempLocalOrder.deliveryFee = calculatedOrder && calculatedOrder.deliveryFee;
    //         tempLocalOrder.customer.addressChanged = isAddressChanged;
    //     }

    //     fetch('https://dev.1andtech.com/secure/api/tapthru/calculateOrder', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         body: `orderjson=${encodeURIComponent(JSON.stringify(tempLocalOrder))}`
    //     }).then(response => response.json())
    //         .then(responseText => {
    //             // console.log(responseText)
    //             setCalculatedOrder(responseText.data);
    //         });
    //     let customer = order.customer;
    //     customer.addressChanged = false;
    //     dispatch(cartAction.updateCustomerInfo(customer))
    // };

    const changeDate = (text) => {
        var d = text.split(' ')[3].replace(',', '')
        var list = temList
        for (let i = 0; i < list.length; i++) {
            if (list[i].currentDate.indexOf(d) > -1) {
                setstoreAvailableHours(order.orderType === 'DELIVERY' ? list[i].deliverySelectableTimes : list[i].pickupSelectableTimes)
                break
            }
        }
    }

    const changeTime = (text) => {
        setScheduleTime(text)
    }



    return (
        <View>
            <View style={{ marginBottom: 16 }}>
                <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 18 }}>Select {props.orderType} Time:</Text>
            </View>
            <View style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }} >
                <View style={{ zIndex: 9999, borderColor: '#dee2e6', borderBottomWidth: 1, width: '100%' }} >
                    <DropDownTime data={storeAvailableDate} onChange={text => changeDate(text)} />
                </View>
                <View style={{ zIndex: 999, borderColor: '#dee2e6', borderBottomWidth: 1, width: '100%' }}>
                    <DropDownTime data={storeAvailableHours} onChange={text => changeTime(text)} />
                </View>

                <TouchableOpacity style={{ width: '100%', borderRadius: 1000, borderWidth: 0, backgroundColor: 'black', zIndex: 10, height: 37, justifyContent: 'center', alignItems: 'center', marginTop: 24 }}
                    onPress={() => { dispatch(cartAction.updateScheduleTime(scheduleTime)), props.updateShow() }}>
                    <Text style={{ fontWeight: 'bold', color: 'white' }}>Update Order Time</Text>
                </TouchableOpacity>

            </View>
            <View style={{ borderWidth: 0 }} >

            </View>
        </View>
    )
}


export default Index