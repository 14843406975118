import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity, TextInput, Text } from 'react-native';
import {
    Fontisto, MaterialCommunityIcons, AntDesign
} from '@expo/vector-icons';


const MultiRequiredOptions = (props) => {
    const [qty, setQty] = useState(0);
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 16, borderBottomWidth: 1, borderBottomColor: '#e7e7e7', alignItems: 'center' }}>
            <Text style={{ fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#191919', flex: 1 }}>{props.option.name}</Text>
            <Text style={{ fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#767676' }}>{props.option.price === 0 ? '' : '+$' + props.option.price.toFixed(2)}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TouchableOpacity style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                    if (qty >= 1) {
                        setQty(qty - 1);
                        props.setQtyLeft(props.qtyLeft + 1);
                        let option = props.option;
                        option.quantity = qty - 1;
                        props.setOptions(option);
                    }

                }}>
                    <AntDesign name='minuscircleo' size={18} color='#494949' />
                </TouchableOpacity>
                <TextInput maxLength={3} value={qty} style={{
                    // marginHorizontal: 16,
                    paddingVertical: 8,
                    paddingHorizontal: 4,
                    minHeight: 18,
                    borderWidth: 1,
                    borderColor: '#e7e7e7',
                    borderRadius: 4,
                    textAlign: 'center',
                    fontSize: 15,
                    fontWeight: '500',
                    outline: 'none',
                    width: 40

                }} />
                <TouchableOpacity style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                    if (props.qtyLeft > 0) {
                        setQty(qty + 1);
                        props.setQtyLeft(props.qtyLeft - 1);
                        let option = props.option;
                        option.quantity = qty + 1;
                        props.setOptions(option);
                    }

                }}>
                    <AntDesign name='pluscircleo' size={18} color='#494949' />
                </TouchableOpacity>
            </View>
        </View >
    )
}


const Options = (props) => {
    const index = props.index;
    let [selectOptions, setSelectedOptions] = useState({
        optionList: []
    });

    const setOptions = (option) => {
        option.groupId = props.groupId;

        if (selectOptions.optionList.indexOf(option) != -1 && !props.required) {
            setSelectedOptions(prevState => {
                let list = prevState.optionList;
                list.splice(selectOptions.optionList.indexOf(option), 1);
                return { ...prevState, optionList: list }
            })
            setOptionsToItem();
        } else {
            if (props.required && props.numOfChoice === 1) {
                setSelectedOptions(prevState => {
                    let selectedOptionList = prevState.optionList;
                    if (selectedOptionList.length > 0) {
                        selectedOptionList.splice(0, 1)
                    }
                    selectedOptionList.push(option);
                    return { ...prevState, optionList: selectedOptionList }
                });
                setOptionsToItem();
            } else if (!props.required && (props.numOfChoice === 0 || selectOptions.optionList.length < props.numOfChoice)) {
                if (selectOptions.optionList.indexOf(option) < 0) {
                    setSelectedOptions(prevState => {
                        let list = prevState.optionList;
                        list.push(option);
                        return { ...prevState, optionList: list }
                    })
                    setOptionsToItem();
                }
            } else if (props.required && props.numOfChoice > 1) {
                let optionIndex = -1;
                selectOptions.optionList.forEach((e, i) => {
                    if (option.id === e.id) {
                        optionIndex = selectOptions.optionList.indexOf(e);
                    }
                })

                if (optionIndex == -1) {
                    setSelectedOptions(prevState => {
                        let list = prevState.optionList;
                        list.push(option);
                        return { ...prevState, optionList: list }
                    })
                    setOptionsToItem();
                } else {
                    if (option.quantity == 0) {
                        setSelectedOptions(prevState => {
                            let list = prevState.optionList;
                            list.splice(optionIndex, 1);
                            return { ...prevState, optionList: list }
                        })
                    } else {
                        setSelectedOptions(prevState => {
                            let list = prevState.optionList;
                            list[optionIndex] = option;
                            return { ...prevState, optionList: list }
                        })
                    }

                    setOptionsToItem();

                }

            }

        }
    }

    const setOptionsToItem = async () => {
        //setCallFunction(true);
        await props.setSelectedOptionGroups(prevState => {
            prevState[index] = selectOptions.optionList;

            return [...prevState];
        })
    }


    return (
        props.options.map((option, index) => {
            if (option.active) {
                if (props.numOfChoice > 1 && props.required) {
                    return (
                        <MultiRequiredOptions key={'o' + option.id.toString()} option={option} qtyLeft={props.qtyLeft} setQtyLeft={props.setQtyLeft} setOptions={setOptions} />
                    )

                } else {
                    return (
                        <TouchableOpacity key={'o' + option.id.toString()} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 16, borderBottomWidth: 1, borderBottomColor: '#e7e7e7' }}
                            onPress={() => {
                                setOptions(option);

                            }}

                        >
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                                <TouchableOpacity onPress={() => {
                                    setOptions(option);
                                }} >
                                    {props.required ? <Fontisto style={{ marginRight: 12 }} name={selectOptions.optionList.indexOf(option) > -1 ? 'radio-btn-active' : 'radio-btn-passive'} size={21} color='#494949' /> :
                                        <MaterialCommunityIcons style={{ marginRight: 12 }} name={selectOptions.optionList && selectOptions.optionList.indexOf(option) > -1 ? 'checkbox-marked' : 'checkbox-blank-outline'} size={24} color='#494949' />
                                    }
                                </TouchableOpacity>
                                <Text style={{ fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#191919', flex: 1 }}>{option.name}</Text>
                            </View>

                            <Text style={{ fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#767676' }}>{option.price === 0 ? '' : '+$' + option.price.toFixed(2)}</Text>
                        </TouchableOpacity>
                    )
                }
            }
        })





    )

}

const OptionGroup = (props) => {
    const optionsG = props.item;
    const index = props.index;
    const r = optionsG.options.filter(e => e.active == true);

    const [qytLeft, setQtyLeft] = useState(optionsG.numOfChoice);

    if (r.length > 0) {
        return (
            <View style={{ marginBottom: 24 }} nativeID={optionsG.groupId} onLayout={(event) => {
                document.getElementById(optionsG.groupId).setAttribute('positionY', event.nativeEvent.layout.top);
            }} >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View>
                        <Text style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 22, color: '#191919' }}>{optionsG.name}</Text>
                        <Text style={{ marginTop: 2, fontSize: 12, fontWeight: '500', lineHeight: 18, color: '#767676' }}>{optionsG.required ? 'Select ' + optionsG.numOfChoice : optionsG.numOfChoice > 0 ? 'Choose up to ' + optionsG.numOfChoice : 'Multiple Choices'}</Text>
                    </View>

                    <View style={{ paddingVertical: 4, paddingHorizontal: 8, borderRadius: 4 }} nativeID={optionsG.groupId + "_Label"}>
                        <Text style={{ fontSize: 14, fontWeight: '500', lineHeight: 20, color: '#767676' }}>{optionsG.required ? 'Required' : 'Optional'}</Text>
                    </View>
                </View>


                <Options options={optionsG.options}
                    required={optionsG.required}
                    numOfChoice={optionsG.numOfChoice}
                    qtyLeft={qytLeft}
                    setQtyLeft={setQtyLeft}
                    index={index}
                    groupId={optionsG.groupId}
                    setItemToBeAdded={props.setItemToBeAdded}
                    setSelectedOptionGroups={props.setSelectedOptionGroups}
                    setOrderItemOptions={props.setOrderItemOptions}
                />

            </View>
        )
    }
}



const OptionGroups = (props) => {


    const setItemToBeAdded = props.setItemToBeAdded;
    const [selectedOptionGroups, setSelectedOptionGroups] = useState(() => {
        let array = [];
        props.options.forEach((e, i) => {
            let optionGroupsMap = [];
            array.push(optionGroupsMap);
        });

        return array;
    });

    const setOrderItemOptions = () => {

        setItemToBeAdded(prevState => {
            let list = [];
            selectedOptionGroups.forEach(e => {

                e.forEach(option => {
                    let optionToBeAdded = {
                        groupId: option.groupId,
                        quantity: option.quantity ? option.quantity : 1,
                        name: option.name,
                        cnName: option.cnName,
                        price: option.price,
                        categoryId: props.category.id,
                        categoryName: props.category.name,
                        categoryCnName: props.category.categoryCnName,
                    }
                    list.push(optionToBeAdded);
                })
            })
            return { ...prevState, orderItemsOptions: list }
        })
    }

    useEffect(() => {
        setOrderItemOptions();
    }, [selectedOptionGroups])

    return (

        <View>
            {props.options.map((item, index) => {
                return (
                    <OptionGroup key={'opg' + index.toString()}
                        item={item}
                        index={index}
                        setItemToBeAdded={setItemToBeAdded}
                        setSelectedOptionGroups={setSelectedOptionGroups}
                        setOrderItemOptions={setOrderItemOptions}
                    />
                )
            })}

        </View>
    )
}

export default OptionGroups;