import {Dimensions, Text, TouchableOpacity, View, StyleSheet, ScrollView} from "react-native";
import * as cartAction from "../../store/actions/cart";
import {MaterialIcons, SimpleLineIcons} from "@expo/vector-icons";
import React from "react";
import {TextInput} from 'react-native-paper'
import {FormControl} from "react-bootstrap";
import {request} from '../../until/request'

const ChangeOrderType = (props) => {

    const stateList = [
        '',
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
        'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC',
        'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'PR'
    ]

    return (
        <View style={{zIndex: 10000, display: props.rightSideItemsShow.showOrderType ? 'flex' : 'none'}}>
            <View style={{
                position: 'absolute',
                left: -5,
                top: 15,
                height: 30,
                borderBottomWidth: 20,
                borderLeftWidth: 20,
                borderTopWidth: 20,
                borderBottomColor: 'transparent',
                borderTopColor: 'transparent',
                borderLeftColor: '#4285F4',
                zIndex: 100000
            }}>
            </View>
            <View style={{
                position: 'absolute',
                left: -451,
                top: 0,
                backgroundColor: '#4285F4',
                zIndex: 100000,
                height: props.orderType === 'DELIVERY' ? 615 : 230,
                maxWidth: 450,
                width: 1000,
                padding: 16,
                borderBottomLeftRadius: 25,
                boxShadow: 'rgb(0 0 0 / 30%) -8px 14px 20px 2px'
            }}>
                <Text style={{color: 'white', fontWeight: 'bold', fontSize: 16}}>Change order type:</Text>

                <View style={{flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20}}>
                    <TouchableOpacity
                        style={{
                            backgroundColor: props.orderType === 'PICK_UP' ? 'black' : 'white',
                            height: 77,
                            width: 77,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 1000
                        }}
                        onPress={() => {
                            props.setOrderType('PICK_UP');
                            props.setAddressVerified(true);
                        }}
                    >
                        <SimpleLineIcons name='handbag' size={30}
                                         color={props.orderType === 'PICK_UP' ? 'white' : 'black'}/>
                        <Text style={{
                            fontWeight: 'bold',
                            color: props.orderType === 'PICK_UP' ? 'white' : 'black'
                        }}>Pickup</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={{
                            backgroundColor: props.orderType === 'DELIVERY' ? 'black' : 'white',
                            height: 77,
                            width: 77,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 1000
                        }}
                        onPress={() => {
                            props.setOrderType('DELIVERY');
                            props.setAddressVerified(false);
                        }}
                    >
                        <MaterialIcons name='delivery-dining' size={30}
                                       color={props.orderType === 'DELIVERY' ? 'white' : 'black'}/>
                        <Text style={{
                            fontWeight: 'bold',
                            color: props.orderType === 'DELIVERY' ? 'white' : 'black'
                        }}>Delivery</Text>
                    </TouchableOpacity>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        marginTop: 20,
                        paddingTop: 20,
                        borderTopWidth: 1,
                        borderTopColor: 'white',
                        display: props.orderType === 'DELIVERY' ? 'block' : 'none'
                    }}>
                    <Text style={{color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5}}>Address</Text>
                    <TextInput
                        autoCompleteType="none"
                        theme={{
                            colors: {
                                primary: (props.customerAddress && props.customerAddress.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                background: 'white',
                                text: 'white'
                            }
                        }}
                        outlineColor={(props.customerAddress && props.customerAddress.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF"}
                        style={{
                            marginBottom: 15,
                            padding: 5,
                            fontWeight: 'bold',
                            borderBottomWidth: 1,
                            borderColor: (props.customerAddress && props.customerAddress.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                            backgroundColor: '#4285F4',
                            outline: (props.customerAddress && props.customerAddress.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                            fontSize: 16,
                            color: '#4285F4',
                            height: 21,
                            marginHorizontal: 10
                        }}
                        placeholder='Address'
                        placeholderTextColor='#AAAAAA'
                        value={props.customerAddress}
                        onChangeText={text => {
                            if (props.customerAddressInitialized) {
                                props.setCustomerAddress(text);
                                if (text.length > 4) {
                                    props.appleMaps.autocomplete(text, (error, data) => {
                                        if (data.results) {
                                            console.log(data.results)
                                            var results = []
                                            data.results.forEach(el => {
                                                var p = {}
                                                p.description = el.displayLines[0] + ' ' + el.displayLines[1]
                                                results.push(p)
                                            })
                                            props.setAddressList(results);
                                            props.setAddressActive(true);
                                            props.setVerifyingAddress(true);
                                        }
                                    })
                                }
                                props.setAddressVerified(false);
                            }
                        }}
                    />
                    <View
                        style={[
                            styles.addressDrop,
                            props.addressActive ?
                                {
                                    position: 'absolute',
                                    display: 'flex'
                                } :
                                {
                                    position: 'relative',
                                    display: 'none'
                                }]} >
                        <ScrollView>
                            {
                                props.addressList.map((item, index) => {
                                    return (
                                        <TouchableOpacity
                                            key={index}
                                            onPress={(event) => {
                                                console.log(item);
                                                props.setAddressActive(false);
                                                props.setVerifyingAddress(false);
                                                props.setCustomerAddress(item.description);
                                            }} >
                                            <Text
                                                style={{
                                                    padding: 5,
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    fontSize: 14
                                                }} >
                                                {item.description}
                                            </Text>
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </ScrollView>
                        <View style={{
                            width: '100%',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            paddingVertical: 5,
                            paddingRight: 10,
                            // backgroundColor: '#fff'
                        }}>
                            <TouchableOpacity
                                style={{
                                    width: '50%',
                                    borderRadius: 1000,
                                    borderWidth: 0,
                                    backgroundColor: 'black',
                                    zIndex: 10,
                                    height: 37,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 24,
                                    marginLeft: 10
                                }}
                                onPress={() => {
                                    props.setAddressActive(false);
                                    props.setVerifyingAddress(false);
                                }}>
                                <Text style={{fontWeight: 'bold', fontSize: 15, color: 'white'}}>Close</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>


                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        display: props.orderType === 'DELIVERY' ? 'block' : 'none',
                        zIndex: -100
                    }}>
                    <Text style={{color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5}}>Apt/Unit/Suite</Text>
                    <TextInput
                        autoCompleteType="none"
                        theme={{
                            colors: {
                                primary: 'white',
                                background: 'white',
                                text: 'white'
                            }
                        }}
                        outlineColor={'white'}
                        style={{
                            marginBottom: 15,
                            padding: 5,
                            fontWeight: 'bold',
                            borderBottomWidth: 1,
                            borderColor: 'white',
                            backgroundColor: '#4285F4',
                            outline: 'white',
                            fontSize: 16,
                            color: '#4285F4',
                            height: 21,
                            marginHorizontal: 10
                        }}
                        placeholder='Apt/Unit/Suite'
                        placeholderTextColor='#AAAAAA'
                        value={props.customerAddress2}
                        onChangeText={text => {
                            props.customerAddressInitialized && props.setCustomerAddress2(text);
                        }}
                    />
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        flexWrap: 'nowrap',
                        display: props.orderType === 'DELIVERY' ? 'block' : 'none',
                        zIndex: -100
                    }}>
                    <View style={{flex: 1}}>
                        <Text style={{color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5}}>City</Text>
                        <TextInput
                            autoCompleteType="none"
                            theme={{
                                colors: {
                                    primary: (props.customerCity && props.customerCity.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                    background: 'white',
                                    text: 'white'
                                }
                            }}
                            outlineColor={(props.customerCity && props.customerCity.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF"}
                            style={{
                                marginBottom: 15,
                                padding: 5,
                                fontWeight: 'bold',
                                borderBottomWidth: 1,
                                borderColor: (props.customerCity && props.customerCity.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                backgroundColor: '#4285F4',
                                outline: (props.customerCity && props.customerCity.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                fontSize: 16,
                                color: '#4285F4',
                                height: 21,
                                marginHorizontal: 10
                            }}
                            placeholder='City'
                            placeholderTextColor='#AAAAAA'
                            value={props.customerCity}
                            onChangeText={text => {
                                if (props.customerAddressInitialized) {
                                    props.setCustomerCity(text);
                                    props.setAddressVerified(false);
                                }
                            }}
                        />
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={{color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5}}>State</Text>
                        <FormControl
                            type='text'
                            as='select'
                            style={{
                                marginBottom: 15,
                                marginLeft: 10,
                                paddingVertical: 10,
                                paddingHorizontal: 20,
                                fontWeight: 'bold',
                                borderBottomWidth: 1,
                                borderLeftWidth: 0,
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderColor: props.customerState && props.customerState.trim().length > 0 ? '#5fff5f' : "#FF5F5FFF",
                                backgroundColor: '#4285F4',
                                outline: 'white',
                                fontSize: 16,
                                color: 'white',
                                height: 40,
                                borderRadius: 0,
                                width: '95%'
                            }}
                            onChange={e => {
                                if (props.customerAddressInitialized) {
                                    props.setCustomerState(e.target.value);
                                    props.setAddressVerified(false);
                                }
                            }}
                            value={props.customerState}
                        >
                            {
                                stateList.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                })
                            }
                        </FormControl>
                    </View>
                    <View style={{flex: 1}}>
                        <Text style={{color: '#fff', fontSize: 16, fontWeight: 'bold', marginLeft: 5}}>ZIP</Text>
                        <TextInput
                            autoCompleteType="none"
                            theme={{
                                colors: {
                                    primary: (props.customerZip && props.customerZip.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                    background: 'white',
                                    text: 'white'
                                }
                            }}
                            outlineColor={(props.customerZip && props.customerZip.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF"}
                            style={{
                                marginBottom: 15,
                                padding: 5,
                                fontWeight: 'bold',
                                borderBottomWidth: 1,
                                borderColor: (props.customerZip && props.customerZip.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                backgroundColor: '#4285F4',
                                outline: (props.customerZip && props.customerZip.trim().length > 0) ? '#5fff5f' : "#FF5F5FFF",
                                fontSize: 16,
                                color: '#4285F4',
                                height: 21,
                                marginHorizontal: 10
                            }}
                            placeholder='ZIP'
                            placeholderTextColor='#AAAAAA'
                            value={props.customerZip}
                            onChangeText={text => {
                                if (props.customerAddressInitialized) {
                                    props.setCustomerZip(text);
                                    props.setAddressVerified(false);
                                }
                            }}
                        />
                    </View>
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        borderTopWidth: 1,
                        borderTopColor: 'white',
                        marginTop: 20
                    }}>
                    <TouchableOpacity
                        style={{
                            width: '50%',
                            borderRadius: 1000,
                            borderWidth: 0,
                            backgroundColor: 'black',
                            zIndex: 10,
                            height: 37,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 24,
                            marginRight: 10,
                            display: props.orderType === 'DELIVERY' ? 'flex' : 'none',
                            opacity: !props.addressVerified && !props.verifyingAddress ? 1.0 : 0.5
                        }}
                        disabled={props.addressVerified || props.verifyingAddress}
                        onPress={async () => {
                            if (props.orderType === 'DELIVERY') {
                                props.setVerifyingAddress(true);
                                props.appleMaps.lookup(props.customerAddress, async (error, detail) => {
                                    if (!error && detail.results.length > 0) {
                                        props.setCustomerAddress(detail.results[0].fullThoroughfare);
                                        props.setCustomerCity(detail.results[0].locality);
                                        props.setCustomerState(detail.results[0].administrativeAreaCode);
                                        props.setCustomerZip(detail.results[0].postCode);
                                        let customer = {
                                            address: props.customerAddress && props.customerAddress.trim(),
                                            address2: props.customerAddress2 && props.customerAddress2.trim(),
                                            city: props.customerCity && props.customerCity.trim(),
                                            state: props.customerState && props.customerState.trim(),
                                            zip: props.customerZip && props.customerZip.trim()
                                        };


                                        let storeInfoDecoded = JSON.parse(localStorage.storeinfo);
                                        let response = await request(`/secure/api/tapthru/getDeliveryAvailability`, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded',
                                            },
                                            body: `customerjson=${JSON.stringify(customer)}&restaurantInfoId=${storeInfoDecoded.id}`
                                        });
                                        let responseText = await response.json();
                                        // You can do anything accroding to your API response
                                        console.log(responseText);
                                        if (responseText.available) {
                                            props.setAddressVerified(true);
                                            props.sendMessage('Delivery available', 'SUCCESS');
                                            if (responseText.postmatesDeliveryFee) {
                                                props.setDeliveryFee(responseText.tapThruDeliveryFee);
                                            } else if (responseText.tapThruDeliveryFee) {
                                                props.setDeliveryFee(responseText.tapThruDeliveryFee);
                                            }
                                        } else {
                                            props.sendMessage(responseText.errorMessage);
                                        }
                                    } else {
                                        props.setAddressVerified(false);
                                        props.sendMessage('Delivery out of range', 'ERROR');
                                    }
                                    props.setVerifyingAddress(false);
                                })
                            }
                        }}>
                        <Text style={{fontWeight: 'bold', color: 'white'}}>Verify</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            width: '50%',
                            borderRadius: 1000,
                            borderWidth: 0,
                            backgroundColor: 'black',
                            zIndex: 10,
                            height: 37,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 24,
                            marginLeft: 10,
                            opacity: props.addressVerified ? 1.0 : 0.5
                        }}
                        disabled={!props.addressVerified}
                        onPress={async () => {
                            // dispatch(cartAction.updateScheduleTime(scheduleTime)), props.updateShow();
                            props.dispatch(cartAction.updateOrderType(props.orderType));

                            switch (props.orderType) {
                                case 'DELIVERY':
                                    let customer = props.order.customer;
                                    customer.address = props.customerAddress.trim();
                                    customer.address2 = props.customerAddress2 && props.customerAddress2.trim();
                                    customer.city = props.customerCity.trim();
                                    customer.state = props.customerState.trim();
                                    customer.zip = props.customerZip.trim();
                                    props.dispatch(cartAction.updateCustomerInfo(customer));
                                    props.setCalculatedOrder(pre => {
                                        return {
                                            ...pre,
                                            customer: customer,
                                            deliveryFee: props.deliveryFee
                                        }
                                    });
                                    props.setAddressVerified(true);
                                    break;
                                case 'PICK_UP':
                                    customer = props.order.customer;
                                    customer.address = '';
                                    customer.address2 = '';
                                    customer.city = '';
                                    customer.state = '';
                                    customer.zip = '';
                                    props.dispatch(cartAction.updateCustomerInfo(customer));
                                    props.setCalculatedOrder(pre => {
                                        return {
                                            ...pre,
                                            customer: customer,
                                            deliveryFee: 0.00
                                        }
                                    });
                                    break;
                            }
                            props.setRightSideItemsShow({
                                tipModalVisible: false,
                                showTimeVisible: false,
                                showOrderType: false,
                                showDiscount: false,
                                showSelfInput: false,
                                showPayment: false
                            });
                        }}>
                        <Text style={{fontWeight: 'bold', color: 'white'}}>Change Order Type</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    addressDrop: {
        width: '100%',
        maxHeight: 300,
        backgroundColor: '#4285F4',
        top: 80,
        left: '0px',
        color: 'white',
        borderColor: '#4285F4',
        borderWidth: 1
    }
})

export default ChangeOrderType;